import React from 'react';
import { Link } from 'react-router-dom';

function AboutUsContentArea() {
  return (
    <>
      <div className="container-fluid py-5 my-5">
        <div className="container pt-5">
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".3s"
            >
              <div className="h-100 position-relative">
                <img
                  src="img/about-1.jpg"
                  className="img-fluid w-75 rounded"
                  alt=""
                  style={{ marginBottom: '25%' }}
                />
                <div
                  className="position-absolute w-75"
                  style={{ top: '25%', left: '25%' }}
                >
                  <img
                    src="img/about-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 col-sm-12 wow fadeIn"
              data-wow-delay=".5s"
            >
              <h5 className="text-primary">About Us</h5>
              <h1 className="mb-4">
                About GalaxySoft And Its Innovative IT Solutions
              </h1>
              <p>
                Get a Personalized IT Solution to Boost Your Business! We'll
                design and set up digital tools just for you, making everything
                work smoother and faster. Whether it's organizing your work
                better or making your website super user-friendly, we've got
                you covered. Let's make your business grow with smart technology
                tailored just for you.
              </p>
              <p className="mb-4">
                Elevate Your Business with Ecommerce Solutions That Expand Your
                Reach and Boost Online Visibility. Reach global audiences,
                attract international customers, and make a splash in the
                market with our tailored solutions. Let's take your business to
                new heights on the internet.
              </p>
              <Link
                to="/details"
                className="btn btn-secondary rounded-pill px-5 py-3 text-white"
              >
                More Details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUsContentArea;
