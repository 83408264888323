import React from 'react';

function ServicesBox() {
  return (
    <>
      <div className="container-fluid services py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Services</h5>
            <h1>Services Built Specifically For Your Business</h1>
          </div>
          <div className="row g-5 services-inner">
            {/* Web Design */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-code fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Web Design</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        Creative Design from Scratch as per client choice and we
                        give them guidance according to the market trends.
                      </li>
                      <li className="mb-4">Convert Design into Websites.</li>
                      <li className="mb-4">
                        Business Introduction Websites with functionalities as
                        per their requirements.
                      </li>
                      <li className="mb-4">
                        We can do websites from small scale to big enterprise
                        solutions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Web Development */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-laptop fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Web Development</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        Customized Solution from Scratch as per client choice
                        and we give them guidance according to market trends.
                      </li>
                      <li className="mb-4">
                        Develop your company enterprise web applications and
                        portals to enhance your business.
                      </li>
                      <li className="mb-4">
                        We offer solutions such as Enterprise Applications,
                        Liferay Web Portals, and WordPress websites.
                      </li>
                      <li className="mb-4">
                        We can create dashboards for your admin to check the
                        products in stock.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* UI/UX Design */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">UI/UX Design</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        We can make your company website adopting new design
                        trends and responsive designs compatible for all media
                        sizes.
                      </li>
                      <li className="mb-4">
                        We adopt agile methodology to deliver projects from
                        start to production.
                      </li>
                      <li className="mb-4">
                        We offer solutions like Enterprise Applications,
                        Liferay Web Portals, and WordPress websites.
                      </li>
                      <li className="mb-4">
                        We create realistic wireframes in Figma to do a proper
                        analysis phase.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* E-Commerce */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fas fa-shopping-cart fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">E-Commerce</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        Customized Solution from Scratch as per client choice
                        and we give them guidance according to market trends.
                      </li>
                      <li className="mb-4">
                        Develop your company enterprise web applications and
                        portals to enhance your business.
                      </li>
                      <li className="mb-4">
                        Increase your sales by creating your own customized
                        e-commerce solution.
                      </li>
                      <li className="mb-4">
                        We can do websites from small scale to big enterprise
                        solutions.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Digital Marketing */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Digital Marketing</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        Showcase your business with business stationery design,
                        including cards, brochures, and ads.
                      </li>
                      <li className="mb-4">
                        Enhance website traffic with better SEO strategies.
                      </li>
                      <li className="mb-4">
                        We can manage your business email campaigns to showcase
                        your products.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Services */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="services-item bg-light">
                <div className="p-4 text-center services-content">
                  <div className="services-content-icon">
                    <i className="fa fa-file-code fa-7x mb-4 text-primary"></i>
                    <h4 className="mb-3">Services</h4>
                    <ul style={{ textAlign: "left" }}>
                      <li className="mb-4">
                        Wireframes, Website Design Concepts, and HTML Framework
                        conversion.
                      </li>
                      <li className="mb-4">
                        Convert Figma or Photoshop designs into frameworks like
                        React, Vue, or Angular.
                      </li>
                      <li className="mb-4">
                        WordPress Themes, WordPress Websites, E-Commerce
                        Solutions, and Liferay (7.0-7.3) Themes.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesBox;
