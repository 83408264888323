import React from 'react'
import { Link } from 'react-router-dom'

function TopNavbar() {
  return (
    <>
    
    <div class="container-fluid bg-dark py-2 d-none d-md-flex">
            <div class="container">
                <div class="d-flex justify-content-between topbar">
                    <div class="top-info">
                        <small class="me-3 text-white-50"><Link><i class="fas fa-map-marker-alt me-2 text-secondary"></i></Link>Braga, Portugal</small>
                        <small class="me-3 text-white-50"><Link><i class="fas fa-envelope me-2 text-secondary"></i></Link>Info@galaxysoft.us</small>
                    </div>
                    <div id="note" class="text-secondary d-none d-xl-flex"><small>Note : We help you to Grow your Business</small></div>
                 
					
					
                </div>
            </div>
        </div>
    
    
    </>
  )
}

export default TopNavbar