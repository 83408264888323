import React from 'react'
import SmallBanner from './SmallBanner'
import ServicesBox from './ServicesBox'
import SmallBannerServices from './SmallBannerServices'


function Services() {
  return (
    <>
    <SmallBannerServices/>
    <ServicesBox/>
    </>
  )
}

export default Services


