
import './App.css';
import Home from './components/Home';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './components/Header';

function App() {
  return (

      <>
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/services' element={<Services />}/>
        <Route path='/contactus' element={<ContactUs />}/>
        <Route path='*' element={<h1>Page not found</h1> }/>
      </Routes>
      <Footer/>
      </BrowserRouter>
      </>

  );
}

export default App;
