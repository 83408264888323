import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
  return (
    <>
      <div className="container-fluid bg-primary">
        <div className="container">
          <nav className="navbar navbar-dark navbar-expand-lg py-0">
            <Link to="/" className="navbar-brand">
              <h1 className="text-white fw-bold d-block">
                Galaxy<span className="text-secondary">Soft</span>
              </h1>
            </Link>
            <button
              type="button"
              className="navbar-toggler me-0"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse bg-transparent" id="navbarCollapse">
              <div className="navbar-nav ms-auto mx-xl-auto p-0">
                <Link to="/" className="nav-item nav-link active text-secondary">
                  Home
                </Link>
                <Link to="/contactus" className="nav-item nav-link">
                  About
                </Link>
                <Link to="/services" className="nav-item nav-link">
                  Services
                </Link>
              </div>
            </div>
            <div className="d-none d-xl-flex flex-shrink-0">
              <div id="phone-tada" className="d-flex align-items-center justify-content-center me-4">
                <a href="tel:+351920064256" className="position-relative animated tada infinite">
                  <i className="fa fa-phone-alt text-white fa-2x"></i>
                  <div className="position-absolute" style={{ top: "-7px", left: "20px" }}>
                    <span>
                      <i className="fa fa-comment-dots text-secondary"></i>
                    </span>
                  </div>
                </a>
              </div>
              <div className="d-flex flex-column pe-4 border-end">
                <span className="text-white-50">Have any questions?</span>
                <span className="text-secondary">Call: +351-920064256</span>
              </div>
              <div className="d-flex align-items-center justify-content-center ms-4">
                <a href="#search">
                  <i className="bi bi-search text-white fa-2x"></i>
                </a>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Navigation;
