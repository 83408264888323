import React from 'react';

function Footer() {
  return (
    <div>
      <>
        <div className="contact-detail position-relative p-5">
          <div className="row g-5 mb-5 justify-content-center">
            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".3s">
              <div className="d-flex bg-light p-3 rounded">
                <div
                  className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                  style={{ width: "64px", height: "64px" }}
                >
                  <i className="fas fa-map-marker-alt text-white"></i>
                </div>
                <div className="ms-3">
                  <h4 className="text-primary">Address</h4>
                  <a
                    href="https://www.google.com/maps/@41.5611656,-8.4451077,13z?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="h5"
                  >
                    Braga, Portugal
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".5s">
              <div className="d-flex bg-light p-3 rounded">
                <div
                  className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                  style={{ width: "64px", height: "64px" }}
                >
                  <i className="fa fa-phone text-white"></i>
                </div>
                <div className="ms-3">
                  <h4 className="text-primary">Call Us</h4>
                  <a href="tel:+0351920064256" className="h5">
                    +0351-920064256
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 wow fadeIn" data-wow-delay=".7s">
              <div className="d-flex bg-light p-3 rounded">
                <div
                  className="flex-shrink-0 btn-square bg-secondary rounded-circle"
                  style={{ width: "64px", height: "64px" }}
                >
                  <i className="fa fa-envelope text-white"></i>
                </div>
                <div className="ms-3">
                  <h4 className="text-primary">Email Us</h4>
                  <a
                    href="mailto:info@galaxysoft.us"
                    className="h5"
                  >
                    info@galaxysoft.us
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid footer bg-dark wow fadeIn" data-wow-delay=".3s">
            <div className="container pt-5 pb-4">
              <div className="row g-5">
                <div className="col-lg-3 col-md-6">
                  <a href="index.html">
                    <h1 className="text-white fw-bold d-block">
                      Galaxy<span className="text-secondary">Soft</span>
                    </h1>
                  </a>
                  <p className="mt-4 text-light">
                    GalaxySoft helps turn your small business dreams into a big reality by
                    elevating your operations to a higher scale.
                  </p>
                </div>
                <div className="col-lg-3 col-md-6">
                  <a href="#" className="h3 text-secondary">
                    Short Link
                  </a>
                  <div className="mt-4 d-flex flex-column short-link">
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>About us
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Contact us
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Our Services
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Our Projects
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <a href="#" className="h3 text-secondary">
                    Help Link
                  </a>
                  <div className="mt-4 d-flex flex-column help-link">
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Terms Of Use
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Privacy Policy
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Helps
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>FAQs
                    </a>
                    <a href="#" className="mb-2 text-white">
                      <i className="fas fa-angle-right text-secondary me-2"></i>Contact
                    </a>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <a href="#" className="h3 text-secondary">
                    Contact Us
                  </a>
                  <div className="text-white mt-4 d-flex flex-column contact-link">
                    <a href="#" className="pb-3 text-light border-bottom border-primary">
                      <i className="fas fa-map-marker-alt text-secondary me-2"></i> Braga
                    </a>
                    <a href="#" className="py-3 text-light border-bottom border-primary">
                      <i className="fas fa-phone-alt text-secondary me-2"></i> +351-920064256
                    </a>
                    <a href="#" className="py-3 text-light border-bottom border-primary">
                      <i className="fas fa-map-marker-alt text-secondary me-2"></i> Pakistan Office
                    </a>
                    <a href="#" className="py-3 text-light border-bottom border-primary">
                      <i className="fas fa-phone-alt text-secondary me-2"></i> +92-3214921322
                    </a>
                    <a href="#" className="py-3 text-light border-bottom border-primary">
                      <i className="fas fa-envelope text-secondary me-2"></i> info@galaxysoft.us
                    </a>
                  </div>
                </div>
              </div>
              <hr className="text-light mt-5 mb-4" />
              <div className="row">
                <div className="col-md-6 text-center text-md-start">
                  <span className="text-light">
                    <a href="#" className="text-secondary">
                      <i className="fas fa-copyright text-secondary me-2"></i>GalaxySoft.us
                    </a>{" "}
                    Hire Us to Boost your Business
                  </span>
                </div>
                <div className="col-md-6 text-center text-md-end">
                  <span className="text-light">
                    Copyright © 2024 GalaxySoft.us. All Rights Reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Footer;
