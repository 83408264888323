import React from 'react';
import BigBanner from './BigBanner';
import AboutUsContentArea from './AboutUsContentArea';
import ServicesHomeWidget from './ServicesHomeWidget';
import ExpertiseAreaBoxes from './ExpertiseAreaBoxes';
import GetInTouch from './GetInTouch';


function Home() {
  return (
   <>
  
      <BigBanner/>
      <AboutUsContentArea/>
      <ServicesHomeWidget/>
      <ExpertiseAreaBoxes/>
      <GetInTouch />

</>
  )
}

export default Home