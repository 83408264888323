import React from 'react'

function ServicesHomeWidget() {
  return (
   <>
   
   <div class="container-fluid services py-5 mb-5">
            <div class="container">
                <div class="text-center mx-auto pb-5 wow fadeIn" data-wow-delay=".3s" style={{maxWidth:" 600px"}}>
                    <h5 class="text-primary">Our Services</h5>
                    <h1>Services Built Specifically For Your Business</h1>
                </div>
                <div class="row g-5 services-inner">
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fa fa-code fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">Web Design</h4>
                                    <p class="mb-4">We design your website to maximize customer to engagement with your business.</p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fa fa-laptop fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">Web Development</h4>
                                    <p class="mb-4">Place your product in the hands of a company wholly dedicated to software development.</p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fa fa-external-link-alt fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">UI/UX Design</h4>
                                    <p class="mb-4">Streamlined UI/UX design solutions tailored for both startups and established enterprises</p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fas fa-shopping-cart fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">E-Commerce</h4>
                                    <p class="mb-4">Customize Developed Solution for Your Business</p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fa fa-envelope-open fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">Digital Marketing</h4>
                                    <p class="mb-4">Craft a Digital Marketing strategy tailored specifically to grow your business. </p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
                        <div class="services-item bg-light">
                            <div class="p-4 text-center services-content">
                                <div class="services-content-icon">
                                    <i class="fa fa-file-code  fa-7x mb-4 text-primary"></i>
                                    <h4 class="mb-3">Services</h4>
                                    <p class="mb-4">We are offering you penalty of services to enhance your business in many ways.</p>
                                    <a href="https://galaxysoft.us/service.html" class="btn btn-secondary text-white px-5 py-3 rounded-pill">Get Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   
   </>
  )
}

export default ServicesHomeWidget