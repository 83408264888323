import React from 'react';
import { Link } from 'react-router-dom';

function ExpertiseAreaBoxes() {
  return (
    <>
      <div className="container-fluid project py-5 mb-5">
        <div className="container">
          <div
            className="text-center mx-auto pb-5 wow fadeIn"
            data-wow-delay=".3s"
            style={{ maxWidth: "600px" }}
          >
            <h5 className="text-primary">Our Services</h5>
            <h1>Our Area of Expertise</h1>
          </div>
          <div className="row g-5">
            {/* Web Design */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-1.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">Web design</h4>
                      <p className="m-0 text-white">Web Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* E-Commerce */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-2.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">E-Commerce</h4>
                      <p className="m-0 text-white">
                        Customize Developed Solution for Your Business
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Mobile Apps */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-3.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">Mobile Apps</h4>
                      <p className="m-0 text-white">Customized Solution</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Web Development */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".3s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-4.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">Web Development</h4>
                      <p className="m-0 text-white">
                        Customized System Development to fasten Business
                        Productivity
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Digital Marketing */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".5s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-5.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">Digital Marketing</h4>
                      <p className="m-0 text-white">Marketing Analysis</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* Business SEO */}
            <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay=".7s">
              <div className="project-item">
                <div className="project-img">
                  <img
                    src="img/project-6.jpg"
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                  <div className="project-content">
                    <Link to="#" className="text-center">
                      <h4 className="text-secondary">Business SEO</h4>
                      <p className="m-0 text-white">
                        Increase your Online Visibility
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpertiseAreaBoxes;
