import React from 'react'
import SmallBanner from './SmallBanner'
import AboutUsContentArea from './AboutUsContentArea'

function ContactUs() {
  return (
   <>
   
  <SmallBanner/>
   <AboutUsContentArea/>
 
   
   
   </>
  )
}

export default ContactUs